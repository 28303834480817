.w-60 {
  width: 60% !important;
}
.input {
  border-radius: 100px !important;
}
.btn-custom {
  background-color: #e42a31 !important;
  color: white !important;
}
.btn-custom.active {
  background-color: #e42a30e0 !important;
  color: white !important;
}
.btn-custom:hover {
  background-color: #e42a30e0 !important;
}
.btn-custom:active {
  background-color: #e42a31 !important;
}
.sub-logo {
  filter: sepia(100%) grayscale(150%);
}
