:root {
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #1C242C;
  --toastify-text-color-error: #fff;

  --toastify-color-success: #14A44D;
  --toastify-color-warning: #fdf8de;
  --toastify-color-error: #ba0000;
}

.Toastify__toast-body {
  font: normal normal 500 var(--q-font-normal) Montserrat;
}

.Toastify__toast-theme--colored.Toastify__toast--info,
.Toastify__toast-theme--colored.Toastify__toast--success,
.Toastify__toast-theme--colored.Toastify__toast--warning,
.Toastify__toast-theme--colored.Toastify__toast--error {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
}

.custom-hidden-toast-container {
  display: none;
}
