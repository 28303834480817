html {
  overflow: hidden;
}
body::-webkit-scrollbar {
  width: 20px;
  height: 7px;
  /* border-left: 1px solid black; */
}
body::-webkit-scrollbar:vertical {
  border-left: 1px solid rgba(0, 0, 0, 0.136);
}
body::-webkit-scrollbar-track {
  background: transparent;
  border: solid 1px transparent;
  border-radius: 15px;
  overflow: auto;
  margin-top: 4rem;
}

body::-webkit-scrollbar-thumb:horizontal {
  background: rgb(212, 17, 17) !important;
  border-radius: 15px;
  min-width: 20px;
  overflow: auto;
  border: 0.5rem solid transparent;
  background-clip: padding-box;
  transition: width 0.2s ease-in-out;
  padding: 10px;
}
body::-webkit-scrollbar-thumb:vertical {
  background: rgb(212, 17, 17);
  border-radius: 15px;
  min-height: 40px;
  overflow: auto;
  border: 0.5rem solid transparent;
  background-clip: padding-box;
  transition: height 0.2s ease-in-out;
  padding: 10px;
}
.responsive-height {
  /* margin-top: 10px; */
  max-height: 100%;
}

@media screen and (max-width: 499px) {
  .responsive-height {
    margin-top: calc(var(--kt-toolbar-height) + calc(0.75rem * 2));
    /* max-height: calc(100vh - calc(var(--kt-toolbar-height)) - 180px); */
  }
}
