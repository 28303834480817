.login-logo-container{
    background-color: rgb(255, 255, 255);
    height: 100vh;
    width: 100vw;
    
}
.login-logo{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
@media screen and (max-width:900px) {
    .login-logo-container{
        display: none !important;
    }
    .login-logo{
        display: none !important;
    }
    .form{
        display: flex !important;
        flex-direction: column;
    }
}